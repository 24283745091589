import { Box, Grid, Typography } from '@mui/material';
import * as React from 'react';
import { CommonMetaHeader } from '../custom/components/common-meta-header';
import { JsonLDTag, JsonLDType } from '../custom/components/json-ld-tag';
import PageBackBtn from '../custom/components/page-back-btn';
import TwoPassRender from '../custom/components/two-pass-render';
import { CustomGtagCategory } from '../custom/constants/gtag-category';

export const Head = () => {
  return (
    <>
      <CommonMetaHeader />
      <JsonLDTag
        type={JsonLDType.BreadcrumbList}
        breadcrumb={[{ name: 'Singapore Funeral Directory', url: 'https://singaporefuneraldirectory.org' }]}
      />
    </>
  );
};

const AboutUsPage = () => {
  return (
    <TwoPassRender>
      <Box padding={1}>
        <Box sx={{ maxWidth: 800, margin: 'auto' }}>
          <Box mt={3}>
            <Typography
              variant="h4"
              component="h1"
              sx={{
                WebkitAlignSelf: 'center',
              }}
            >
              About SFD
            </Typography>
          </Box>

          <Box mt={4}>
            <Typography
              variant="h5"
              component="h2"
              sx={{
                WebkitAlignSelf: 'center',
              }}
            >
              How Singapore Funeral Directory was born
            </Typography>
          </Box>

          <Box mt={3}>
            <Typography>
              Ten minutes after the dinner, mum rested her head on the dining
              table. We thought she took a rest but she did not respond when we
              called her. We knew about her underlying medical conditions. We
              quickly performed CPR while waiting for the arrival of the
              ambulance. The doctors in the hospital declared her death and
              issued the death certificate.
            </Typography>
          </Box>

          <Box mt={2}>
            <Typography>
              The older generations are taboo about death hence they hardly talk
              and prepare for their own final journey. Most of the time, the
              family is unaware of what to do. Hence, Singapore Funeral
              Directory was founded to achieve the following objectives.
            </Typography>
          </Box>

          <Box mt={4}>
            <Typography component="div">
              <ol
                style={{
                  maxWidth: 800,
                  margin: 'auto',
                  paddingInlineStart: '20px',
                }}
              >
                <li>
                  Help families to find reliable and compassionate funeral
                  directors to provide a dignified final journey for the
                  deceased.
                </li>
                <li>
                  Help the community to understand and educate about death
                  matters.
                </li>
                <li>
                  Help Funeral Directors to use technology to achieve the above
                  point 1 and 2
                </li>
              </ol>
            </Typography>
          </Box>
        </Box>

        <Box mt={4}>
          <Grid container sx={{ maxWidth: 800, margin: 'auto' }}>
            <PageBackBtn gtagCategory={CustomGtagCategory.About_Us_Back} />
          </Grid>
        </Box>
      </Box>
    </TwoPassRender>
  );
};

export default AboutUsPage;
